// Insert your styling here.
.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-bg {
  padding-bottom: 29.583333%;
  .cover();
  min-height: 400px;
}
.not-front .header-bg {
  padding-bottom: 0;
  min-height: 240px;
  height: 240px;
}
.not-front {
  #block-system-main {
    min-height: 700px;
  }
}
.page-header {
  .container();
  position: relative;
  top: -60px;
  color: #fff;
  border: 0 none;
  text-transform: uppercase;
  font-weight: bold;
}
.btn {
  text-transform: uppercase;
  font-size: 13px;
  padding: 13px 50px;
}

.front .region.region-content {
  .block-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
}

#block-views-frontpage-blocks-block {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.view.view-frontpage-blocks {
  .view-content{
    .col {
      > .node-pool-frontpage-block,
      > .node-service {
        text-align: center;
        img {
          margin: 0 auto;
        }
        h3 {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: bold;
          a {
            text-decoration:none;
            color: #444343;
          }
        }
        .field.field-name-body {
          color: #9a9a9a;
          font-style: italic;
          font-size:16px;
          line-height: 1.6;
          max-width: 200px;
          margin: 0 auto;
        }
        .field.field-name-node-link  {
          margin-top: 30px;
          font-size: 14px;
          a {
            text-transform: uppercase;
            text-decoration:none;
            color: #cdcdcd;
            border-top: 1px solid #ebebeb;
            padding: 10px 15px;
            font-weight: bold;
            &:hover {
              color: @brand-danger;
            }
          }
        }
      }
    }
  }
}


/* Flexslider */
.flex-direction-nav a {
  line-height: 48px;
  height: 48px;
}
.flex-control-nav {
  bottom: 20px;
  z-index: 10;
}
.flexslider {
  border: 0;
  margin-bottom: 0;
  background:transparent;
}
.flexslider .slides > li a img{visibility:visible !important; opacity:1 !important;filter:alpha(opacity=100) !important;}

#block-views-frontpage-slideshow-block {
  .flexslider .slides > li {
    -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      overflow: hidden;
  }
  .flex-caption {
    position: absolute;
    text-align:center;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%,-50%);
     -ms-transform: translate(-50%,-50%);
     transform: translate(-50%,-50%);
     p {
       font-style:italic;
       font-size: 25px;
     }
    //background-color: rgba(0,0,0,0.6);
    color: #fff;
    //padding: 30px 60px;
    h1, h3, h2 {
      font-size: 28px;
      white-space:nowrap;
      @media (min-width: @screen-sm-min) {
        font-size: 42px;
      }
      @media (min-width: @screen-md-min) {
        font-size: 65px;
      }
      font-weight: bold;
      text-transform: uppercase;
    }
    h4 {
      font-style: italic;
      font-size: 18px;
      @media (min-width: @screen-sm-min) {
        font-size: 28px;
      }
    }
    .btn {
      margin-top: 30px;
    }
  }
}


/* Facebook feed  */
.carousel li {
  margin-right: 0;
  }
#block-views-facebook-feed-block {
  padding-top: 10vh;
  padding-bottom: 10vh;
	background: #efefee url('../images/fb-bakgrund.jpg') no-repeat;
  .cover();
	//background-position: right 5% bottom -70%;
	.view.view-facebook-feed {
    > .view-header {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 60px;
      color: #a4a4a4;
      font-weight:bold;
    }
		.views-row {
			a {
				color: #fff;
				text-decoration: none;
			}
			&:hover {
				color: #026664;
				a {
					color: #026664;
				}
			}
		}
    #block-bean-facebook {
      text-align: center;
      margin-bottom: 30px;
    }
    .block-title {
      color: #fff;
    }
    .field.field-name-field-text {
      color: #fff;
    }
		.view-content .flexslider {
	  	  .flex-viewport {
	  		  /*height: 356px;*/
	  		  max-width: 1140px;
	  		  margin: 0 auto;
	      }
		  .flex-direction-nav a {
			  &:before {
				  font-size: 48px;
				  font-weight: bold;
			  }
		  }
	 	  .slides > li {
	 	   > div {
			   background: #fff;
	 		   /*padding-left: 15px;
	 		   padding-right: 15px;*/
         position:relative;
			   margin-right: 15px;
			   margin-left: 15px;
         /*border: 1px solid #f0f0f0;*/
			   .field.field-name-field-image {
				   .field-item {
					   text-align: center;
					   /*height: 220px;
					   line-height: 215px;*/
					   img {
						   line-height: 0;
						   display: inline-block;
						   vertical-align: middle;
					   }
				   }
			   }
			   .group-fb-link {
				   text-decoration: none;
				   color: #969595;
				   display: block;
			   }
			   .field.field-name-post-date {
				   padding-left: 30px;
				   padding-right: 30px;
           margin-top: 15px;
           font-weight: bold;
           text-transform: uppercase;
           color: @brand-danger;
           .field-item {
             display:inline-block;
             padding-bottom: 10px;
             padding-right: 10px;
             border-bottom: 1px solid #e1e1e1;
           }
			   }
			   .field.field-name-body {
				   padding-left: 30px;
				   padding-right: 30px;
				   padding-top: 15px;
				   padding-bottom: 15px;
			   }
         .field.field-name-field-story {
				   padding-left: 30px;
				   padding-right: 30px;
           line-height: 30px;
         }
			   .group-wrapper.field-group-div {
           /*position: absolute;
           bottom: 0;
           width: 100%;*/
           border-top: 1px solid #eeeeee;
           position:relative;
           white-space:nowrap;
				   color: #969595;
				   font-size: 12px;
				   .field.field-name-field-comments-count {
					   display: inline-block;
					   background: transparent;
					   padding: 10px 10px 10px 20px;
					   border-radius: 20px;
					   .field-item:before {
			  		   font-family: FontAwesome;
			  			  content: @fa-var-comment-o;
						    margin-right: 5px;
						    color: #dadada;
                font-size: 20px;
					    }
				   }
				   .field.field-name-field-likes-count {
				   	  display: inline-block;
					    background: transparent;
					    padding: 10px 10px;
					    border-radius: 20px;
					    .field-item:before {
			  			  font-family: FontAwesome;
			  			  content: @fa-var-heart-o;
						    margin-right: 5px;
						    color: #dadada;
                font-size: 20px;
					    }
				   }
				   .field.field-name-field-link {
             i {
               font-size: 20px;
               color: #eeeeee;
               &:hover {
                 color: @brand-danger;
               }
             }
             &:hover {
               cursor:pointer;
             }
				     display: inline-block;
             right: 15px;
					   background: transparent;
					   border-radius: 20px;
             text-transform: uppercase;
             .readmore {
               position: absolute;
               top: 0;
               right: 15px;
               line-height: 49px;
               padding-left: 15px;
               border-left: 1px solid #eeeeee;
               a {
                 font-weight: bold;
                 text-decoration: none;
                 text-transform: uppercase;
                 color: #c7c7c7;
                 &:hover {
                   color: @brand-danger;
                 }
               }
             }
					   /*.field-item:before {
			  			  font-family: FontAwesome;
			  			  content: @fa-var-share;
						    margin-right: 5px;
						    color: #dadada;
					   }*/
				   }
			   }
	 	   }
     }
	 	}
	}
  .more-link {
    text-align: center;
    margin-top: 30px;
    a {
      text-decoration: none;
      color: #fff;
      border: 1px solid #fff;
      text-align: center;
      padding: 8px 12px;
      display: inline-block;
      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

#block-views-poolemployees-block-1 {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

/* Employees */
.view.view-poolemployees {
  .col {
    margin-bottom: 30px;
    text-align:center;
  }
  > .view-header {
    text-align:center;
    text-transform: uppercase;
    margin-bottom: 60px;
    color: #a4a4a4;
    font-weight:bold;
  }
  .field {
    &.field-name-field-pool-employee-image {
      img {
        border: 10px solid #ee3030;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &.field-name-title-field {
      text-transform: uppercase;
      font-weight:bold;
      padding-bottom: 10px;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 15px;
      width: 100px;
      white-space: nowrap;
    }
    &.field-name-body  {
      font-style:italic;
      a {
        color: @text-color;
      }
    }
  }
  .vcenter {
    float:none;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 30px;
  }
  .bubble {
    position: relative;
    width: 255px;
    text-align:left;
    line-height: 1.6;
    padding: 20px;
    background: #FFFFFF;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: #ebebeb solid 1px;
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #FFFFFF;
      display: block;
      width: 0;
      z-index: 1;
      left: -10px;
      top: 20px;
      @media (max-width: 518px) {
        border-color: #FFFFFF transparent;
        border-width: 0 10px 10px;
        top: -10px;
        left: 115px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #ebebeb;
      display: block;
      width: 0;
      z-index: 0;
      left: -11px;
      top: 20px;
      @media (max-width: 518px) {
        border-color: #ebebeb transparent;
        border-width: 0 10px 10px;
        top: -11px;
        left: 115px;
      }
    }
  }
}

#block-bean-contact-form {
  //padding-top: 10vh;
  h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #b1b1b1;
    font-weight:bold;
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1;
  }
  .field.field-name-title-field {
    font-size: 18px;
    text-transform: uppercase;
    color: #b1b1b1;
    font-weight:bold;
    margin-bottom: 40px;
    line-height: 1;
  }
  overflow: hidden;
  .btn {
    .btn-block;
  }
  &:before {
    content: "––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––";
    color: #e6e6e6;
    letter-spacing: 10px;
    padding-bottom: 10vh;
  }
  .entityform {
    .grippie {
      display: none;
    }
    .form-control.form-text {
      height: 50px;
    }
    #edit-field-contact-name {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-user;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#dadada;
      }
    }
    #edit-field-contact-email {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-envelope;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#dadada;
      }
    }
    #edit-field-phone {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-phone;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#dadada;
      }
    }
    #edit-field-contact-message {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-comments;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#dadada;
      }
    }
    textarea.form-control {
      min-height: 150px;
    }
  }
}


/* References */
.view-references {
  > .more-link {
    clear: both;
    text-align: center;
    padding-top: 20px;
    a {
      text-transform: uppercase;
      text-decoration:none;
      color: #cdcdcd;
      border-top: 1px solid #ebebeb;
      padding: 10px 15px;
      font-weight: bold;
    }
  }
  > .view-header {
    text-align:center;
    text-transform: uppercase;
    margin-bottom: 60px;
    color: #a4a4a4;
    font-weight:bold;
  }
  > .view-content {
    .col {
      > .node {
        position:relative;
      }
      margin-bottom: 30px;
      &:hover {
        .group-wrapper {
          max-height: 200px;
          .field {
            &.field-name-body {
              opacity: 1;
              display: block;
            }
          }
          .group-links {
            opacity: 1;
            display: block;
          }
        }
      }
    .field.field-name-field-image {
      padding-bottom: 59px;
    }
    .group-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      overflow: hidden;
      background-color: @brand-danger;
      color: #fff;
      padding: 20px;
      font-size: 14px;
      max-height: 59px;
      -webkit-transition: max-height .5s linear;
      	-moz-transition: max-height .5s linear;
      	transition: max-height .5s linear;
      .field {
        &.field-name-title-field {
          display: inline-block;
          font-weight: bold;
        }
        &.field-name-field-location {
          display: inline-block;
          position: relative;
          padding-left: 15px;
          &:before {
            content: "|";
            font-weight: 300;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 5px;
          }
        }
        &.field-name-body {
          //display: none;
          padding-top: 15px;
          padding-bottom: 15px;
          line-height: 1.6;
          opacity: 0;
          font-style:italic;
          -webkit-transition: opacity 1s;
          	-moz-transition: opacity 1s;
          	transition: opacity 1s;
        }
        &.field-name-node-link {
          display:inline-block;
          position:relative;
          padding-left: 15px;
          a {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            text-decoration: none;
            &:hover {
              color: #ddd;
            }
          }
          &:before {
            content: "|";
            font-weight: 300;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 5px;
          }
        }
      }
      .group-links {
        //display: none;
        opacity: 0;
        text-align: right;
        .sharethis-buttons {
          display: inline-block;
          .st_sharethis_custom {
            color: #fff;
            &:hover {
              cursor: pointer;
              color: #ddd;
            }
            &:before {
		  		   font-family: FontAwesome;
		  			 content: @fa-var-share-alt;
            }
          }
        }
      }
    }
    }
  }
  &.view-display-id-page,
  &.view-display-id-page_1 {
     > .view-content {
       .group-wrapper {
         background-color: #303030;
       }
     }
  }
}

.node-reference.view-mode-full {
  h3 {
    margin-top: 0;
    text-transform: uppercase;
    font-weight:bold;
    color: #444243;
    line-height: 1.4;
  }
  .sidebar {
    font-size: 13px;
  }
  .field {
    margin-bottom: 30px;
    .field-label {
      font-weight: normal;
      color: #aaaaaa;
       margin-bottom: 15px;
    }
    &.field-name-field-services,
    &.field-name-field-categories {
      > .field-items {
        > .field-item {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    &.field-name-field-tags {
      > .field-items {
        > .field-item {
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 15px;
          a {
            padding: 3px 5px;
            border-radius: 3px;
            background-color: #eeeeee;
            text-decoration: none;
            color: #8d8d8d;
            &:hover {
              background-color:@brand-danger;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .flex-control-nav {
    bottom: 20px;
    left: 20px;
    z-index: 10;
    width: auto;
  }
  .flex-control-paging li a {
    width: 22px;
    height: 22px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    &.flex-active {
      background: rgba(255,255,255,0.9);
    }
  }
}

#block-views-references-block-1 {
  padding-bottom: 10vh;
  overflow-x: hidden;
  &:before {
    content: "––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––";
    color: #e6e6e6;
    letter-spacing: 10px;
    padding-bottom: 10vh;
  }
}
#block-views-references-block-2 {
  padding-top: 60px;
  padding-bottom: 70px;
  .block-title {
    font-size: 22px;
    margin-bottom:30px;
    font-weight: 400;
    color: #797979;
  }
}

.isotope-options {
	/*padding-left: 15px;*/
  text-align: left;
	.isotope-filters {
		margin-bottom: 20px;
		list-style: none;
		border-bottom: 1px solid #d0d0d0;
		/*padding: 0 0 7px 0;*/
		li {
			display: inline;
			a {
				color: #aeaeae;
				text-decoration: none;
				font-size: 15px;
        font-weight: bold;
				padding: 5px 10px;
        display: inline-block;
				@media (max-width: @screen-xs) {
					padding: 5px 5px;
				}
				&.active {
					border-bottom: 5px solid #d0d0d0;
					color: #000;
				}
			}
		}
	}

}


/* Services */

.node-service {
  h2 {
    text-transform: uppercase;
    color: #444343;
    font-weight: bold;
  }
  em {
    color: #9a9a9a;
  }
  .field.field-name-body {
    ul {
      list-style:none;
      li {
        list-style:none;
        a {
          text-decoration:none;
          font-style:italic;
          color: #8b8b8b;
          &:before {
            content: "\00BB";
            color:@brand-danger;
            margin-right: 5px;
          }
          &:hover {
            color: @brand-danger;
          }
        }
      }
    }
  }
  .field.field-name-title-field {
    h2 {
      margin-top: 0;
    }
  }
}
#block-views-services-block-1 {
  .view-services {
    .view-content > div {
      .views-field.views-field-title-field {
        height:30px;
        margin-bottom: 15px;
        a {
          text-decoration: none;
          color: #b0b0b0;
          text-transform: uppercase;
          font-weight: bold;
        }
        .img-responsive {
          display:inline-block;
          margin-right: 15px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
          opacity: 0.3;
        }
      }
      &:hover,
      &.active0 {
        .views-field.views-field-title-field {
          a {
            color: @brand-danger;
          }
          .img-responsive {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
          }
        }
      }
    }
  }
}

.view-brands {
  .view-content {
    margin-top: 30px;
    margin-bottom: 30px;
    .views-field-field-image {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
          filter: gray; /* IE6-9 */
      	  -webkit-filter: grayscale(99%);
          &:hover {
            filter: none;
            -webkit-filter: none;
          }
    }
    .row > .col {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

/* Testimonials */

.view-testimonials {
  .col {
    margin-bottom: 30px;
    .field {
      &.field-name-field-image {
        .pull-left;
        margin-left: 24px;
        margin-right: 20px;
        img {
          border: 1px solid #e3e3e3;
          border-radius: 50%;
        }
      }
      &.field-name-body {
        position: relative;
        padding: 30px;
        background: #FFFFFF;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: #d8d8d8 solid 1px;
        font-size: 14px;
        margin-bottom: 30px;
        color: #6f6f6f;
        font-style:italic;
        line-height: 1.7;
        &:after {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 10px 12px 0;
          border-color: #FFFFFF transparent;
          display: block;
          width: 0;
          z-index: 1;
          bottom: -10px;
          left: 60px;
        }
        :before {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 10px 12px 0;
          border-color: #d8d8d8 transparent;
          display: block;
          width: 0;
          z-index: 0;
          bottom: -11px;
          left: 60px;
        }
      }
      &.field-name-title-field {
        margin-top: 10px;
        font-weight:600;
      }
      &.field-name-field-location {
        font-style:italic;
      }
    }
  }
}

#block-views-testimonials-block {
  background-color: #fbfbfb;
  border-top:1px solid #e4e4e4;
  border-bottom:1px solid #e4e4e4;
  padding-top: 60px;
  padding-bottom: 40px;
  .block-title {
    .container();
    font-size: 22px;
    font-weight: 400;
    color: #797979;
    margin-bottom: 30px;
  }
}


/* Node pages */

.node.view-mode-full {
  .field {
    &.field-name-body {
      line-height: 1.7;
    }

  }
}

#sliding-popup .agree-button,
#sliding-popup .decline-button {
  .btn;
  .btn-default;
  border-color: #fff;
  background-color: transparent;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  text-shadow: none;
}
#sliding-popup .popup-content #popup-text {
  margin-top: 15px;
}
