// Insert your styling here.
.footer {
  .region.region-footer {
    .row();
  }
  color: #969595;
  background-color: #313131;
  font-size: 13px;
  a {
    color: #969595;
    text-decoration: none;
    &:hover {
      color:@brand-danger;
    }
  }
  .block-title {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .block {
    &:first-child h2.block-title {
      margin-top: 21px;
    }
  }
  .menu {
    padding: 0;
    li {
      margin-bottom: 15px;
      a {
        text-decoration:none;
        &:before {
          content: "\00BB";
          color:@brand-danger;
          margin-right: 5px;
        }
      }
    }
  }
  #block-bean-about {
    .entity {
      max-width: 330px;
    }
  }
  #block-bean-links {
    .field-item {
      margin-bottom: 15px;
      a {
        text-decoration:none;
        &:before {
          content: "\00BB";
          color:@brand-danger;
          margin-right: 5px,
        }
      }
    }
  }
  .field {
    margin-bottom: 30px;
    &.field-name-field-contact-email {
      position:relative;
      margin-left: 30px;
      &:before {
			  font-family: FontAwesome;
			  content: @fa-var-envelope-o;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        color: #4a4a4a;
      }
    }
    &.field-name-field-phone {
      position:relative;
      margin-left: 30px;
      &:before {
			  font-family: FontAwesome;
			  content: @fa-var-phone;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        color: #4a4a4a;
      }
    }
    &.field-name-field-address {
      position:relative;
      margin-left: 30px;
      &:before {
			  font-family: FontAwesome;
			  content: @fa-var-map-marker;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        color: #4a4a4a;
      }
    }
  }
}