// Insert your styling here.
body.navbar-is-fixed-top {
  @media (min-width: @grid-float-breakpoint){
    padding-top: 116px !important;
  }
}
body.admin-menu.navbar-is-fixed-top {
  @media (min-width: @grid-float-breakpoint){
    padding-top: 143px !important;
  }
}
.nav-container {
  .container-fluid();
  @media (min-width: @screen-lg-min) {
    .container();
  }
}
#block-block-1 {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-right: 15px;
  color: #cccccc;
  position:relative;
  &:before {
    font-family: FontAwesome;
    content: @fa-var-phone;
    position: absolute;
    top: 2px;
    bottom: 0;
    left: -15px;
    color: #cccccc;
  }
  a {
    text-decoration: none;
    color: #cccccc;
    font-weight: 600;
  }
}  
#block-locale-language {
 
}
.region.region-navigation {
  text-align: right;
  .block {
    display: inline-block;
  }
  #block-locale-language {
    border-left: 1px solid #f1f1f1;
    padding-top: 15px;
  }
  #block-bean-social-links {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #cccccc;
    .field {
      &.field-name-title-field {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-right: 15px;
      }
      display: inline-block;
      &.field-name-field-link {
        .field-item {
          display: inline-block;
          font-size: 20px;
          margin-right: 15px;
          a {
            background-color: #d3d3d3;
            color: #fff;
            border-radius: 50%;
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align:center;
            vertical-align: middle;
            &.facebook {
              &:hover {
                background-color: #608cd7;
              }
            }
            &.instagram {
              &:hover {
                background-color: #517fa6;
              }
            }
            &.linkedin {
              &:hover {
                background-color: #006699;
              }
            }
          }
        }
      }
    }
  }
	.language-switcher-locale-url {
    padding-left: 15px;
		list-style: none;
		li {
			list-style: none;
			display: inline;
      &.first {
        a {
          margin-right: 5px;
        }
      }
			a {
				display:inline-block;
        color: @text-color;
				height: 30px;
				width: 30px;
				line-height: 30px;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
        border-radius: 50%;
				&:hover, &.active {
					border: 1px solid #dbdbdb;
				}
			}
		}
	}
}
body.admin-menu.adminimal-menu::before {
  height: auto;
}
.navbar {
  .logo {
    padding-left: 15px;
    img {
      max-height: 60px;
    }
     @media (min-width: @grid-float-breakpoint){
       position:relative;
       top: 10px;
       img {
         max-height: none;
       }
     } 
  }
  margin-bottom: 0;
  .navbar-nav {
    > li {
      > a {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  @media (min-width: @grid-float-breakpoint){
    .nav-container {
      display: -webkit-flex;
      display: flex;
    }
    .navbar-header {
      margin-right: 70px;
    }
    .navbar-header,.navbar-brand,.navbar .navbar-nav,.navbar .navbar-nav > li {
      float: none;
      display: inline-block;
    }
    .region.region-navigation {
      border-bottom: 1px solid #f1f1f1;
    }
    .navbar-collapse.collapse {
      width: 100%;
      clear: none;
      display: inline-block;
      border-left: 1px solid #f1f1f1;
    }
    .navbar-nav {
      > li {
        > a {
          margin-left: 15px;
          margin-right: 15px;
          line-height: 35px;
          &.active, &:hover {
            box-shadow: inset 0 5px 0 0 #ee3030;
          }
        }
      }
    }
  }
}
//dropdown
#navbar {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	ul.nav li.dropdown:hover ul.dropdown-menu {
		/*display: block;*/
	}
  ul.nav li.dropdown ul.dropdown-menu {
    margin-top: 0;
    @media (max-width: @grid-float-breakpoint){
	    position:relative;
	    width: 100%;
	    margin-bottom: 15px;
	    -webkit-box-shadow: none;
	    -moz-box-shadow: none;
	    box-shadow: none;
      padding-left: 15px;
    }
  }
  
  ul.nav li.dropdown:hover ul.dropdown-menu li.expanded ul.dropdown-menu {display:none;}
  ul.nav li.dropdown:hover ul.dropdown-menu li.expanded:hover ul.dropdown-menu {display:block;}
  @media (min-width: @grid-float-breakpoint){
    .dropdown-menu .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }
}
.navbar-toggle {
  top: 10px;
}

